
@media all and (min-width: 1000px) {
  .rowLane {
    flex-direction: row;
  }
}

@media all and (max-width: 1000px) {
  .rowLane {
    flex-direction: column;
    flex-shrink: 0;
  }
}

.rowLane {
  width: 100%;
  display: flex;
  justify-content: center;
}

.App {
  font-family: Helvetica, Arial;
  background-color: #282c34;
  color: white;
  flex-direction: column;
  align-items: center;

  display: flex;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

header {
  background-color: #282c34;
  width: 100%;
  color: #f0f0f0;
  text-align: center;
}

header h1 {
  margin: .3em;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column h2 {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.column hr {
  border: 0;
  margin: 2px 0px 5px 2px;
  background: /* gradient can be an image */
    linear-gradient(
      to right,
      #006E90 0%,
      #C95BAB 20%,
      #90D7FF 40%,
      #F18F01 60%,
      #99C24D 80%
    )                    left
                   bottom
                   #777
                   no-repeat;
  width: 80%;
  height: 4px;
}

.column p {
  padding: 10px;
  margin: 10px;

}

.column h3 {
  padding: 10px;
  margin: 10px;
}

.switch {
  color: #657b83;
  cursor: pointer;
}


textarea {
  width: 100%;
  min-height: 350px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: vertical;
}

.generate {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #;
  font-size: 2em;
  color: #fdf6e3;
  background-color: #586e75;
}

.colWidth { max-width: 500px;}

.JsonSyntaxHL, .errorPre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #E8E2B7;
  font-size: 0.8em;
  border: solid 7px transparent;
  padding: 10px;
  /* border-image: linear-gradient( */
  /*     red, */
  /*     purple */
  /* ) 1 100%; */
  /* width: 80%; */
  padding-left: 10%;
  overflow-x: auto;
}

.JsonSyntaxHL span { word-wrap: break-word; }

 .key { color: #90D7FF;  }
 .string { color: #F18F01; }
 .number { font-weight: bold; color: #99C24D; }
 .boolean { font-weight: bold; color: #C95BAB; }
 .error { font-weight: bold; color: #e00;}


/* unvisited link */

 a:link {
  color: #C95BAB;
}

/* visited link */
a:visited {
  color: #C95BAB;
}

/* mouse over link */
a:hover {
  color: #F18F01;
}

/* selected link */
a:active {
  color: #90D7FF;
}
